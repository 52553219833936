@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
body{
  background: #00205a;

}
h1,h2,h3,p,ul,li{
  margin: 0;
}
.brand{
  width: 100%;
  min-height: calc(100vh - (160));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.message-holder {
  width: 80vw;
  height: 80vw;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px){
  .message-holder {
    width: 40vw;
    height: 40vw;
  }
}
.logo{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  filter: blur(11px);
  z-index: 0;
  width: 90%;
}
.heading{
  width: 100%;
  height: 100%;
  position: absolute;
  font-weight: 800;
  /* line-height: 0; */
  animation: spinningTitle 30s infinite linear;
}
@keyframes spinningTitle {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.letter{
  color: white;
  font-size: 9vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (min-width: 768px){
  .letter{
    font-size: 4vw;
  }

}
.letter span{
  display: block;
  text-align: center;
  text-shadow: 0 0 3px rgba(0,0,0,.5);
}
.component-score{
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.message{
  font-family: 'Bebas Neue';
  text-align: center;
  position: relative;
  z-index: 10;
  font-size: 25vw;
  line-height: 14vw;
  color: white;
  text-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
  font-weight: bold; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 10px;
}
@media screen and (min-width: 768px){
  .message{
    font-size: 14vw;
  }
}
.score{
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 1rem;
  color: #00205a;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
}
.score p{
  margin: 0;
  font-weight: bold;
  color: white;
  padding: 0 16px;
  font-weight: 300;
}
.game-status{
  font-size: .75rem;
  order: 0;
}
.score p:nth-child(2){
  order: 1;
}
.preloader{
  background: #00205a;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.preloader img{
  filter: blur(0px);
}
.preloader.load-out{
  animation: loadOut 1s ease forwards;
  opacity: 1;
  visibility: visible;
}
.stats{
  margin-bottom: 1rem;
}
.stats--inner{
  display: flex;
  align-items: start;
  justify-content: center;
  padding: .5rem 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  font-size: .688rem;
  line-height: 1.5;
}
.stats--inner div{
  flex: 1;
}
.stats--inner .home{
  text-align: right;
}
.sponsor{
  color: #ffffff;
  display: block;
  text-decoration: none;
  text-align: center;
  position: relative;
  z-index: 20;
}
.sponsor img{
  max-width: 80px;
}
@keyframes loadOut {
  0%{
    opacity: 1;
    visibility: visible;
  }
  100%{
    opacity: 0;
    visibility: hidden;
  }
}